import React, { useState, useEffect } from "react";
import { getDataRatioOutletUser } from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import Highcharts from "highcharts";
import numeral from "numeral";

function ModalDetailSTO({
  onCloseModal,
  Group_Id,
  Group_Name,
  priode,
  rank,
  ranks,
  Id_type,
  idTeam,
  color,
}) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const onCloseModalProperty = () => {
    onCloseModal();
  };

  const [dataUser, setDataUser] = useState([]);

  const dataUsers = [];
  for (const key in dataUser) {
    if (Object.hasOwnProperty.call(dataUser, key)) {
      const element = dataUser[key];
      dataUsers.push(element);
    }
  }
  const sortedDataOutletUser = dataUsers.sort((a, b) => b.m - a.m);

  useEffect(() => {
    showLoader();
    const payload = {
      id_type: Id_type,
      rank: rank ? rank : ranks,
      search_id: "",
      product_list_group: Group_Id,
      periode: priode,
      team_id: idTeam,
    };
    getDataRatioOutletUser(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataUser(res.data.data);
          initializeDataTables();
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          window.location = "/";
          hideLoader();
        }
      });
  }, [Group_Id]);

  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const script = document.createElement("script");
      script.src = `${process.env.PUBLIC_URL}/js/content.js`;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [dataUser]);

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>

      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className=" pb-12 mx-[3%] my-[2%] max-w-8xl">
          {/* Modal content */}
          <div className="bg-white rounded-lg shadow">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <button
                onClick={onCloseModalProperty}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-12 h-12 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="overflow-y-auto overflow-x-auto max-h-[800px]">
              <div className="p-4 md:p-5 relative ">
                <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                  <h3 className="font-semibold text-lg mx-2 ">
                    Data User{" "}
                    <span className="text-redBrave">{Group_Name}</span>
                  </h3>
                  <div>
                    <div className="card-body">
                      <table
                        className="table table-bordered table-hover table-scroll"
                        id="example200"
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          display: "block",
                          overflow: "scroll",
                          height: "420px",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        <thead
                          style={{
                            backgroundColor: "#437998",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            zIndex: "10",
                            position: "sticky",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr>
                            <th
                              style={{
                                left: "0",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#437998",
                              }}
                            >
                              NO
                            </th>
                            <th
                              style={{
                                left: "60px",
                                zIndex: "10",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#437998",
                              }}
                            >
                              UID
                            </th>
                            {/* <th>CT</th>
                    <th>RB</th>
                    <th>SLD</th>
                    <th>KU</th>
                    <th>TNR</th> */}
                            <th>M-3</th>
                            <th>M-2</th>
                            <th>M-1</th>
                            <th>M</th>
                            <th>GROWTH YTD</th>
                            <th>GROWTH</th>
                          </tr>
                        </thead>

                        <tbody style={{ textAlign: "right" }}>
                          {sortedDataOutletUser.map((list, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    textAlign: "center",
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                  width={"1%"}
                                >
                                  {index + 1}.
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    left: "60px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  {list.user_id === null ||
                                  list.user_name === null ? (
                                    <>Not Connected</>
                                  ) : (
                                    <>
                                      {list.user_id} / {list.user_name}
                                    </>
                                  )}
                                </td>
                                {/* <td>
                          <span
                            className={`${
                              parseFloat(list.current_ct) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(list.current_ct)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          {list.current_rb === "100.00"
                            ? "100 %"
                            : `${list.current_rb} %`}
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(list.current_saldo) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(list.current_saldo)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(list.last_ku) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {formatToRupiah(list.last_ku)}
                          </span>
                        </td>
                        <td className="text-center">
                          <span
                            className={`${
                              parseFloat(list.lifetime) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {list.lifetime}
                          </span>
                        </td> */}
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(list.m_3) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_3)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(list.m_2) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_2)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(list.m_1) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_1)}
                                  </span>
                                </td>
                                <td className="text-green-500">
                                  {" "}
                                  <span
                                    className={`${
                                      parseFloat(list.m) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m)}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <span
                                    className={`${
                                      parseFloat(list.growth_ytd) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {list.growth_ytd} %
                                  </span>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <span
                                    className={`${
                                      parseFloat(list.growth) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {list.growth} %
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalDetailSTO;

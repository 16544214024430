import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  getDataRatioSubDetail,
  getDataRatioArea,
  getDataRatioChanel,
  getDataRatioOutletUser,
} from "../../service/ratio/RatioService";
import Full from "../../load/Loading";
import "../../App.css";
import Swal from "sweetalert2";
import Highcharts from "highcharts";
import numeral from "numeral";

function ChartFour({
  tipe,
  tipers,
  mark,
  marks,
  month,
  idTeam,
  names,
  nameLast,
  id_type,
}) {
  const targetRefArea = useRef(null);
  const targetRefChanel = useRef(null);
  const targetRefOutlet = useRef(null);
  const targetRefOutletUser = useRef(null);
  //Loading
  const [loader, showLoader, hideLoader] = Full();

  //get localstrorage
  const is_manager = localStorage.getItem("is_manager");

  const params = useParams();
  const type = params.type;
  const name = params.name;
  const idDivisi = params.idDivisi;

  //id
  const [idUser, setIdUser] = useState("");
  const [idChanel, setIdChanel] = useState("");
  //update waktu
  const [updateTime, setUpdate] = useState("");
  //name tabel
  const [nameProduk, setNameProduct] = useState("");
  const [nameProdukName, setNameProductName] = useState("");
  const [nameChanel, setNameChanel] = useState("");
  const [nameOutlet, setNameOutlet] = useState("");
  const [nameOutletUser, setNameOutletUser] = useState("");
  //data pie
  const [ratioDetail, setDataRatioSubDetail] = useState([]);

  //data tabel
  const [dataRatioArea, setDataRatioArea] = useState({});
  const [dataRatioChanel, setDataRatioChanel] = useState({});
  const [dataRatioOtlet, setDataRatioOutlet] = useState({});
  const [dataRatioOtletUser, setDataRatioOutletUser] = useState({});
  //show tabel
  const [showDetailArea, setShowDetailArea] = useState(false);
  const [showDetailChanel, setShowDetailChanel] = useState(false);
  const [showDetailOutlet, setShowDetailOutlet] = useState(false);
  const [showDetailOutletUser, setShowDetailOutletUser] = useState(false);

  const [growthTotalArea, setGrowthTotalArea] = useState("");
  const [growthYtdTotalArea, setGrowthYtdTotalArea] = useState("");
  const [selisihBDU, setSelisihBDU] = useState("");

  const [kontribusiTotalProduct, setKontribusiTotalProduct] = useState("");
  const [kontribusiTotalPdProduct, setKontribusiTotalPdProduct] = useState("");
  const [growthTotalProduct, setGrowthTotalProduct] = useState("");
  const [growthYtdTotalProduct, setGrowthYtdTotalProduct] = useState("");

  const [kontribusiTotalChanel, setKontribusiTotalChanel] = useState("");
  const [growthTotalChanel, setGrowthTotalChanel] = useState("");
  const [growthYtdTotalChanel, setGrowthYtdTotalChanel] = useState("");

  const [kontribusiTotalOutlet, setKontribusiTotalOutlet] = useState("");
  const [growthTotalOutlet, setGrowthTotalOutlet] = useState("");
  const [growthYtdTotalOutlet, setGrowthYtdTotalOutlet] = useState("");

  //tabel 1
  const data = [];
  for (const key in ratioDetail) {
    if (Object.hasOwnProperty.call(ratioDetail, key)) {
      const element = ratioDetail[key];
      data.push(element);
    }
  }

  //table 2
  const dataArea = [];
  for (const key in dataRatioArea) {
    if (Object.hasOwnProperty.call(dataRatioArea, key)) {
      const element = dataRatioArea[key];
      dataArea.push(element);
    }
  }
  //tabel 3
  const dataChanel = [];
  for (const key in dataRatioChanel) {
    if (Object.hasOwnProperty.call(dataRatioChanel, key)) {
      const element = dataRatioChanel[key];
      dataChanel.push(element);
    }
  }
  //tabel 4
  const dataOutlet = [];
  for (const key in dataRatioOtlet) {
    if (Object.hasOwnProperty.call(dataRatioOtlet, key)) {
      const element = dataRatioOtlet[key];
      dataOutlet.push(element);
    }
  }

  const dataOutletUser = [];
  for (const key in dataRatioOtletUser) {
    if (Object.hasOwnProperty.call(dataRatioOtletUser, key)) {
      const element = dataRatioOtletUser[key];
      dataOutletUser.push(element);
    }
  }

  //pertama load
  useEffect(() => {
    dataPieChart();
  }, [names, tipers, nameLast]);

  //show chart
  const dataPieChart = () => {
    showLoader();
    setShowDetailArea(false);
    setShowDetailChanel(false);
    setShowDetailOutlet(false);
    setShowDetailOutletUser(false);
    let payload;
    if (is_manager === "0") {
      if (tipe == "BDU" || tipers == "BDU") {
        payload = {
          rank: mark ? mark : marks,
          type: tipe ? tipe : tipers,
          search_name: "detail_user",
          search_id: idTeam,
          user_id: "",
          team_id: idTeam,
          periode: month,
          id_type: id_type,
        };
      } else if (tipe == "STO" || tipers == "STO") {
        payload = {
          rank: mark ? mark : marks,
          type: tipe ? tipe : tipers,
          search_name: "detail_product",
          search_id: idTeam,
          user_id: "",
          team_id: idTeam,
          periode: month,
          id_type: id_type,
        };
      }
    } else {
      if (tipe == "BDU" || tipers == "BDU") {
        payload = {
          rank: mark ? mark : marks,
          type: tipe ? tipe : tipers,
          search_name: "detail_user",
          search_id: idTeam,
          user_id: "",
          team_id: idTeam,
          periode: month,
          id_type: id_type,
        };
      } else if (tipe == "STO" || tipers == "STO") {
        payload = {
          rank: mark ? mark : marks,
          type: tipe ? tipe : tipers,
          search_name: "detail_product",
          search_id: idTeam,
          user_id: "",
          team_id: idTeam,
          periode: month,
          id_type: id_type,
        };
      }
    }
    getDataRatioSubDetail(payload)
      .then((res) => {
        if (res.data.success == true) {
          setUpdate(res.data.data.update_time);
          initializeDataTables();
          if (tipe == "BDU" || tipers == "BDU") {
            setDataRatioSubDetail(res.data.data["BDU"]);
            setGrowthTotalArea(res.data.growth_total);
            setGrowthYtdTotalArea(res.data.growth_ytd_total);
            setSelisihBDU(res.data.selisih_bdu);
            initializeDataTables();
          } else if (tipe == "STO" || tipers == "STO") {
            setDataRatioSubDetail(res.data.data["STO"]);
            setDataRatioArea(res.data.data["STO"]);
            setKontribusiTotalProduct(res.data.kontribusi_total);
            setKontribusiTotalPdProduct(res.data.kontribusi_gp_total);
            setGrowthTotalProduct(res.data.growth_total);
            setGrowthYtdTotalProduct(res.data.growth_ytd_total);
            initializeDataTables();
          }
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //fungsi tabel 2
  const detailProduct = (id_user, next_data, name) => {
    showLoader();
    setIdUser(id_user);
    if (targetRefArea.current) {
      targetRefArea.current.scrollIntoView({ behavior: "smooth" });
    }
    setShowDetailChanel(false);
    setShowDetailOutlet(false);
    setShowDetailOutletUser(false);
    setNameProduct(id_user);
    setNameProductName(name);
    const payload = {
      rank: mark ? mark : marks,
      type: tipe ? tipe : tipers,
      search_name: next_data,
      search_id: id_user,
      user_id: "",
      team_id: idTeam,
      periode: month,
      id_type: id_type,
    };

    getDataRatioArea(payload)
      .then((res) => {
        if (res.data.success == true) {
          initializeDataTables();
          if (tipe == "BDU" || tipers == "BDU") {
            setDataRatioArea(res.data.data["BDU"]);
            setKontribusiTotalProduct(res.data.kontribusi_total);
            setKontribusiTotalPdProduct(res.data.kontribusi_gp_total);
            setGrowthTotalProduct(res.data.growth_total);
            setGrowthYtdTotalProduct(res.data.growth_ytd_total);
            initializeDataTables();
          } else if (tipe == "STO" || tipers == "STO") {
            setDataRatioArea(res.data.data["STO"]);
            setKontribusiTotalProduct(res.data.kontribusi_total);
            setKontribusiTotalPdProduct(res.data.kontribusi_gp_total);
            setGrowthTotalProduct(res.data.growth_total);
            setGrowthYtdTotalProduct(res.data.growth_ytd_total);
            initializeDataTables();
          }
          hideLoader();
          setShowDetailArea(true);
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //fungsi tabel 3
  const detailChannel = (id_user, next_data, name) => {
    showLoader();
    setShowDetailOutlet(false);
    setShowDetailOutletUser(false);
    setIdChanel(id_user);
    if (targetRefChanel.current) {
      targetRefChanel.current.scrollIntoView({ behavior: "smooth" });
    }

    setShowDetailOutlet(false);
    setNameChanel(name);
    const payload = {
      rank: mark ? mark : marks,
      type: tipe,
      search_name: next_data,
      search_id: id_user,
      user_id: idUser,
      team_id: idTeam,
      periode: month,
      id_type: id_type,
    };

    getDataRatioChanel(payload)
      .then((res) => {
        if (res.data.success == true) {
          if (tipe == "BDU" || tipers == "BDU") {
            setDataRatioChanel(res.data.data["BDU"]);
            setKontribusiTotalChanel(res.data.kontribusi_total);
            setGrowthTotalChanel(res.data.growth_total);
            setGrowthYtdTotalChanel(res.data.growth_ytd_total);
            initializeDataTables();
          } else if (tipe == "STO" || tipers == "STO") {
            setDataRatioChanel(res.data.data["STO"]);
            setKontribusiTotalChanel(res.data.kontribusi_total);
            setGrowthTotalChanel(res.data.growth_total);
            setGrowthYtdTotalChanel(res.data.growth_ytd_total);
            initializeDataTables();
          }
          hideLoader();
          setShowDetailChanel(true);
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //fungsi tabel 4
  const detailOutlet = (id_user, next_data, name) => {
    showLoader();
    setShowDetailOutletUser(false);
    if (targetRefOutlet.current) {
      targetRefOutlet.current.scrollIntoView({ behavior: "smooth" });
    }
    setNameOutlet(name);
    const payload = {
      rank: mark ? mark : marks,
      type: tipe ? tipe : tipers,
      search_name: next_data,
      search_id: id_user,
      user_id: idUser,
      team_id: idTeam,
      periode: month,
      product_id: idChanel ? idChanel : "",
      id_type: id_type,
    };
    getDataRatioChanel(payload)
      .then((res) => {
        if (res.data.success == true) {
          if (tipe == "BDU" || tipers == "BDU") {
            setDataRatioOutlet(res.data.data["BDU"]);
            setKontribusiTotalOutlet(res.data.kontribusi_total);
            setGrowthTotalOutlet(res.data.growth_total);
            setGrowthYtdTotalOutlet(res.data.growth_ytd_total);
            initializeDataTables();
          } else if (tipe == "STO" || tipers == "STO") {
            setDataRatioOutlet(res.data.data["STO"]);
            setKontribusiTotalOutlet(res.data.kontribusi_total);
            setGrowthTotalOutlet(res.data.growth_total);
            setGrowthYtdTotalOutlet(res.data.growth_ytd_total);
            initializeDataTables();
          }
          hideLoader();
          setShowDetailOutlet(true);
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  const detailUserOutlet = (id_userOutlet, name) => {
    showLoader();
    setNameOutletUser(name);
    if (targetRefOutletUser.current) {
      targetRefOutletUser.current.scrollIntoView({ behavior: "smooth" });
    }
    // setIdOutlet(id_user);
    const payload = {
      rank: mark ? mark : marks,
      search_id: id_userOutlet,
      periode: month,
      product_list_id: idChanel,
    };

    getDataRatioOutletUser(payload)
      .then((res) => {
        if (res.data.success == true) {
          setDataRatioOutletUser(res.data.data);
          initializeDataTables();
          setShowDetailOutletUser(true);
          hideLoader();
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
          hideLoader();
        }
      });
  };

  //konversi nilai
  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  // Inisialisasi variabel total
  let totalCurrentCt = 0;
  let totalCurrentSaldo = 0;
  let totalLastKU = 0;
  let totalM3 = 0;
  let totalM2 = 0;
  let totalM1 = 0;
  let totalM = 0;
  let countNonZeroM3 = 0;
  let countNonZeroM2 = 0;
  let countNonZeroM1 = 0;
  let countNonZeroM = 0;

  // Map data dan tambahkan nilai ke total
  data.forEach((list) => {
    totalCurrentCt += parseInt(list.current_ct);
    totalCurrentSaldo += parseInt(list.current_saldo);
    totalLastKU += parseInt(list.last_ku);
    totalM3 += parseInt(list.m_3);
    totalM2 += parseInt(list.m_2);
    totalM1 += parseInt(list.m_1);
    totalM += parseInt(list.m);
    if (parseInt(list.m_3) !== 0) {
      countNonZeroM3++;
    }
    if (parseInt(list.m_2) !== 0) {
      countNonZeroM2++;
    }
    if (parseInt(list.m_1) !== 0) {
      countNonZeroM1++;
    }
    if (parseInt(list.m) !== 0) {
      countNonZeroM++;
    }
  });

  // Inisialisasi variabel total
  let totalM3UnitAreaGrand = 0;
  let totalM2UnitAreaGrand = 0;
  let totalM1UnitAreaGrand = 0;
  let totalMUnitAreaGrand = 0;
  let totalM3AreaGrand = 0;
  let totalM2AreaGrand = 0;
  let totalM1AreaGrand = 0;
  let totalMAreaGrand = 0;

  // Map dataArea dan tambahkan nilai ke total
  dataArea.forEach((list) => {
    totalM3UnitAreaGrand += parseInt(list.m_3_unit);
    totalM2UnitAreaGrand += parseInt(list.m_2_unit);
    totalM1UnitAreaGrand += parseInt(list.m_1_unit);
    totalMUnitAreaGrand += parseInt(list.m_unit);
    totalM3AreaGrand += parseInt(list.m_3);
    totalM2AreaGrand += parseInt(list.m_2);
    totalM1AreaGrand += parseInt(list.m_1);
    totalMAreaGrand += parseInt(list.m);
  });

  // Inisialisasi variabel total
  let totalM3UnitChanel = 0;
  let totalM2UnitChanel = 0;
  let totalM1UnitChanel = 0;
  let totalMUnitChanel = 0;
  let totalM3Chanel = 0;
  let totalM2Chanel = 0;
  let totalM1Chanel = 0;
  let totalMChanel = 0;

  // Map dataChanel dan tambahkan nilai ke total
  dataChanel.forEach((list) => {
    totalM3UnitChanel += parseInt(list.m_3_unit);
    totalM2UnitChanel += parseInt(list.m_2_unit);
    totalM1UnitChanel += parseInt(list.m_1_unit);
    totalMUnitChanel += parseInt(list.m_unit);
    totalM3Chanel += parseInt(list.m_3);
    totalM2Chanel += parseInt(list.m_2);
    totalM1Chanel += parseInt(list.m_1);
    totalMChanel += parseInt(list.m);
  });
  // Inisialisasi variabel total
  let totalM3UnitOutlet = 0;
  let totalM2UnitOutlet = 0;
  let totalM1UnitOutlet = 0;
  let totalMUnitOutlet = 0;
  let totalM3Outlet = 0;
  let totalM2Outlet = 0;
  let totalM1Outlet = 0;
  let totalMOutlet = 0;
  let countOutletZeroM3 = 0;
  let countOutletZeroM2 = 0;
  let countOutletZeroM1 = 0;
  let countOutletZeroM = 0;

  // Map dataOutlet dan tambahkan nilai ke total
  dataOutlet.forEach((list) => {
    totalM3UnitOutlet += parseInt(list.m_3_unit);
    totalM2UnitOutlet += parseInt(list.m_2_unit);
    totalM1UnitOutlet += parseInt(list.m_1_unit);
    totalMUnitOutlet += parseInt(list.m_unit);
    totalM3Outlet += parseInt(list.m_3);
    totalM2Outlet += parseInt(list.m_2);
    totalM1Outlet += parseInt(list.m_1);
    totalMOutlet += parseInt(list.m);
    if (parseInt(list.m_3) !== 0) {
      countOutletZeroM3++;
    }
    if (parseInt(list.m_2) !== 0) {
      countOutletZeroM2++;
    }
    if (parseInt(list.m_1) !== 0) {
      countOutletZeroM1++;
    }
    if (parseInt(list.m) !== 0) {
      countOutletZeroM++;
    }
  });

  const sortedData = data.sort((a, b) => b.m - a.m);
  const sortedDataArea = dataArea.sort((a, b) => b.m - a.m);
  const sortedDataChanel = dataChanel.sort((a, b) => b.m - a.m);
  const sortedDataOutlet = dataOutlet.sort((a, b) => b.m - a.m);
  const sortedDataOutletUser = dataOutletUser.sort((a, b) => b.m - a.m);

  useEffect(() => {
    if (sortedDataArea.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(document).ready(function() {
            $('#table5').DataTable({
                "retrieve": true,
                "paging": false,
                "lengthChange": false,
                "searching": true,
                "ordering": true,
                "info": false,
                "autoWidth": false,
                "responsive": false,
            });
        });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [sortedDataArea]);

  const initializeDataTables = () => {
    // Initialize DataTables library here after data is fetched and rendered
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/js/content.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  };

  return (
    <>
      <section className="">
        {tipe == "BDU" || tipers == "BDU" ? (
          <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
            <h3 className="font-semibold text-lg mx-2 mb-3">
              Detail Data dari <span className="text-redBrave">{nameLast}</span>
            </h3>

            <div className="">
              <div className="card-body" style={{ marginTop: "-20px" }}>
                <table
                  className="table table-bordered table-hover table-scroll"
                  id="example13"
                  style={{
                    fontSize: "12px",
                    fontWeight: "semiBold",
                    display: "block",
                    overflow: "scroll",
                    height: "450px",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#437998",
                      color: "white",
                      textAlign: "center",
                      top: "0",
                      zIndex: "10",
                      position: "sticky",
                      fontWeight: "reguler",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          left: "0",
                          zIndex: "5",
                          position: "sticky",
                          verticalAlignAlign: "middle",
                          backgroundColor: "#437998",
                        }}
                      >
                        No
                      </th>
                      <th
                        style={{
                          left: "60px",
                          zIndex: "10",
                          position: "sticky",
                          verticalAlignAlign: "middle",
                          backgroundColor: "#437998",
                        }}
                      >
                        UID
                      </th>
                      <th>CT</th>
                      <th>RB</th>
                      <th>SLD</th>
                      <th>KU</th>
                      <th>TNR</th>
                      <th>M-3</th>
                      <th>M-2</th>
                      <th>M-1</th>
                      <th>M</th>
                      <th>GROWTH YTD</th>
                      <th>GROWTH</th>
                    </tr>
                  </thead>

                  <tbody style={{ textAlign: "right" }}>
                    {sortedData.map((list, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              textAlign: "center",
                              left: "0px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                            width={"1%"}
                          >
                            {index + 1}.
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontStyle: "italic",
                              color: "#3b82f6",
                              left: "60px",
                              zIndex: "5",
                              position: "sticky",
                              backgroundColor: "#ffffff",
                            }}
                          >
                            <span
                              onClick={() =>
                                detailProduct(list.id, list.next, list.name)
                              }
                            >
                              {list.id} / {list.name}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.current_ct) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.current_ct)}
                            </span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {list.current_rb === "100.00"
                              ? "100 %"
                              : `${list.current_rb} %`}
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.current_saldo) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.current_saldo)}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.last_ku) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {formatToRupiah(list.last_ku)}
                            </span>
                          </td>
                          <td className="text-center">
                            <span
                              className={`${
                                parseFloat(list.lifetime) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {list.lifetime}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.m_3) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {formatToRupiah(list.m_3)}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.m_2) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {formatToRupiah(list.m_2)}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${
                                parseFloat(list.m_1) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {formatToRupiah(list.m_1)}
                            </span>
                          </td>
                          <td className="text-green-500">
                            {" "}
                            <span
                              className={`${
                                parseFloat(list.m) < 0 ? "text-red-500" : ""
                              }`}
                            >
                              {formatToRupiah(list.m)}
                            </span>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={`${
                                parseFloat(list.growth_ytd) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {list.growth_ytd} %
                            </span>
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <span
                              className={`${
                                parseFloat(list.growth) < 0
                                  ? "text-red-500"
                                  : ""
                              }`}
                            >
                              {list.growth} %
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot
                    style={{ textAlign: "right" }}
                    className="sticky bottom-0 z-10"
                  >
                    <tr className="bg-white border border-slate-800 font-bold">
                      <td
                        colSpan={"2"}
                        style={{
                          textAlign: "center",
                          left: "0px",
                          zIndex: "5",
                          position: "sticky",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        USER TRANSAKSI
                      </td>
                      <td colSpan={"5"}></td>
                      <td>
                        {countNonZeroM3} / {data.length}
                      </td>
                      <td>
                        {countNonZeroM2} / {data.length}
                      </td>
                      <td>
                        {countNonZeroM1} / {data.length}
                      </td>
                      <td>
                        {countNonZeroM} / {data.length}
                      </td>
                      <td colSpan={2}></td>
                    </tr>
                    <tr className="bg-white border border-slate-800 font-bold">
                      <td
                        colSpan={"2"}
                        style={{
                          textAlign: "center",
                          left: "0px",
                          zIndex: "5",
                          position: "sticky",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        TOTAL
                      </td>
                      <td>
                        {" "}
                        <span
                          className={`${
                            parseFloat(totalCurrentCt) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalCurrentCt)}
                        </span>
                      </td>
                      <td style={{ backgroundColor: "white" }}></td>
                      <td>
                        {" "}
                        <span
                          className={`${
                            parseFloat(totalCurrentSaldo) < 0
                              ? "text-red-500"
                              : ""
                          }`}
                        >
                          {formatToRupiah(totalCurrentSaldo)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalLastKU) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalLastKU)}
                        </span>
                      </td>
                      <td style={{ backgroundColor: "white" }}></td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalM3) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalM3)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalM2) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalM2)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalM1) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalM1)}
                        </span>
                      </td>
                      <td>
                        <span
                          className={`${
                            parseFloat(totalM) < 0 ? "text-red-500" : ""
                          }`}
                        >
                          {formatToRupiah(totalM)}
                        </span>
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          textAlign: "center",
                        }}
                      >
                        <span
                          className={`${
                            parseFloat(growthYtdTotalArea) < 0
                              ? "text-red-500"
                              : ""
                          }`}
                        >
                          {growthYtdTotalArea} %
                        </span>
                      </td>
                      <td
                        style={{
                          backgroundColor: "white",
                          textAlign: "center",
                        }}
                      >
                        <span
                          className={`${
                            parseFloat(growthTotalArea) < 0
                              ? "text-red-500"
                              : ""
                          }`}
                        >
                          {growthTotalArea} %
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        ) : null}

        <div ref={targetRefArea}>
          {showDetailArea || tipe == "STO" || tipers == "STO" ? (
            <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
              <h3 className="font-semibold text-lg mx-2 mb-3">
                {tipe == "STO" ? (
                  <>
                    Detail Data dari{" "}
                    <span className="text-redBrave">{names}</span>
                  </>
                ) : tipers == "STO" ? (
                  <>
                    Detail Data dari{" "}
                    <span className="text-redBrave">{nameLast}</span>
                  </>
                ) : (
                  <>
                    Detail Data dari{" "}
                    <span className="text-redBrave">
                      {" "}
                      {nameProduk} / {nameProdukName}
                    </span>
                  </>
                )}
              </h3>

              <div className="card-body" style={{ marginTop: "-20px" }}>
                <div>
                  <table
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                      display: "block",
                      overflow: "scroll",
                      height: "480px",
                      width: "100%",
                      position: "relative",
                    }}
                    className="table table-bordered table-hover table-scroll"
                    id={`table5`}
                  >
                    <thead
                      style={{
                        backgroundColor: "#6BB7B7",
                        color: "white",
                        textAlign: "center",
                        top: "0",
                        zIndex: "10",
                        position: "sticky",
                        verticalAlignAlign: "middle",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr></tr>
                      <tr>
                        <th
                          rowSpan={"2"}
                          width="1%"
                          style={{
                            left: "0",
                            zIndex: "5",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            backgroundColor: "#6BB7B7",
                          }}
                        >
                          NO
                        </th>
                        <th
                          rowSpan={"2"}
                          width="9.6%"
                          style={{
                            left: "62px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            backgroundColor: "#6BB7B7",
                          }}
                        >
                          PRD
                        </th>
                        <th
                          rowSpan={"2"}
                          width="9.6%"
                          style={{
                            left: "145px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            backgroundColor: "#6BB7B7",
                          }}
                        >
                          PRD GROUP
                        </th>
                        <th colSpan={"4"} width="25%">
                          UNIT
                        </th>
                        <th colSpan={"4"} width="25%">
                          VALUE
                        </th>
                        <th colSpan={"2"} width="5%">
                          KONTRIBUSI
                        </th>
                        <th rowSpan={"2"} width="5%">
                          GROWTH YTD
                        </th>
                        <th rowSpan={"2"} width="5%">
                          GROWTH
                        </th>
                      </tr>
                      <tr>
                        <th width="6.3%">M-3</th>
                        <th width="6.3%">M-2</th>
                        <th width="6.3%">M-1</th>
                        <th width="6.3%">M</th>
                        <th width="6.3%">M-3</th>
                        <th width="6.3%">M-2</th>
                        <th width="6.3%">M-1</th>
                        <th width="6.3%">M</th>
                        <th width="6.3%">PG</th>
                        <th width="6.3%">TOTAL</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "right" }}>
                      {sortedDataArea.map((list, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                textAlign: "center",
                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              {index + 1}.
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                textDecoration: "underline",
                                fontStyle: "italic",
                                color: "#3b82f6",
                                cursor: "pointer",
                                left: "62px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <span
                                onClick={() =>
                                  detailChannel(list.id, list.next, list.name)
                                }
                              >
                                {list.name}
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                left: "145px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              {list.product_list_group}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_3_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_2_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_1_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_unit)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_3) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_2) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_1) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1)}
                              </span>
                            </td>
                            <td className="text-green-500">
                              <span
                                className={`${
                                  parseFloat(list.m) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m)}
                              </span>
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {list.kontribusi_gp}%
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {list.kontribusi}%
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.growth_ytd) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.growth_ytd} %
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.growth) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.growth} %
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot className="bg-white border border-slate-800 font-bold sticky bottom-0 z-10">
                      <tr
                        style={{ textAlign: "right" }}
                        className="bg-white border border-slate-800 font-bold sticky bottom-0 z-10"
                      >
                        <td
                          colSpan={3}
                          style={{
                            textAlign: "center",
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontStyle: "italic",
                            color: "#3b82f6",
                            left: "0px",
                            zIndex: "5",
                            position: "sticky",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          <span
                            onClick={() =>
                              detailChannel(
                                "all",
                                "detail_channel",
                                `Grand Total ${names}`
                              )
                            }
                          >
                            GRAND TOTAL
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(
                                totalM3UnitAreaGrand.toLocaleString("id-ID")
                              ) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3UnitAreaGrand)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(
                                totalM2UnitAreaGrand.toLocaleString("id-ID")
                              ) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2UnitAreaGrand)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(
                                totalM1UnitAreaGrand.toLocaleString("id-ID")
                              ) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1UnitAreaGrand)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(
                                totalMUnitAreaGrand.toLocaleString("id-ID")
                              ) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalMUnitAreaGrand)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM3AreaGrand) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3AreaGrand)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM2AreaGrand) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2AreaGrand)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM1AreaGrand) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1AreaGrand)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalMAreaGrand) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalMAreaGrand)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(kontribusiTotalPdProduct) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {kontribusiTotalPdProduct} %
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(kontribusiTotalProduct) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {kontribusiTotalProduct} %
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(growthYtdTotalProduct) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {growthYtdTotalProduct} %
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(growthTotalProduct) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {growthTotalProduct} %
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: "30px" }} ref={targetRefChanel}>
          {showDetailChanel ? (
            <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
              <h3 className="font-semibold text-lg mx-2 ">
                Detail Data dari{" "}
                <span className="text-redBrave">
                  {" "}
                  {name} - {nameChanel}
                </span>
              </h3>
              <div className="">
                <div className="card-body">
                  <table
                    className="table table-bordered table-hover table-scroll"
                    id="example14"
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                      display: "block",
                      overflow: "scroll",
                      height: "435px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#71AF7B",
                        color: "white",
                        textAlign: "center",
                        top: "0",
                        zIndex: "10",
                        position: "sticky",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr className="text-center">
                        <th
                          rowSpan={"2"}
                          style={{
                            left: "0",
                            zIndex: "5",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            backgroundColor: "#71AF7B",
                          }}
                        >
                          NO
                        </th>
                        <th
                          rowSpan={"2"}
                          style={{
                            left: "60px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            backgroundColor: "#71AF7B",
                          }}
                        >
                          TIPE
                        </th>
                        <th colSpan={"4"}>UNIT</th>
                        <th colSpan={"4"}>VALUE</th>
                        <th rowSpan={"2"}>KONTRIBUSI</th>
                        <th rowSpan={"2"}>GROWTH YTD</th>
                        <th rowSpan={"2"}>GROWTH</th>
                      </tr>
                      <tr className="text-center">
                        <th>M-3</th>
                        <th>M-2</th>
                        <th>M-1</th>
                        <th>M</th>
                        <th>M-3</th>
                        <th>M-2</th>
                        <th>M-1</th>
                        <th>M</th>
                      </tr>
                    </thead>

                    <tbody style={{ textAlign: "right" }}>
                      {sortedDataChanel.map((list, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                textAlign: "center",
                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                              width={"1%"}
                            >
                              {index + 1}.
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                textDecoration: "underline",
                                fontStyle: "italic",
                                color: "#3b82f6",
                                cursor: "pointer",
                                left: "60px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <span
                                onClick={() =>
                                  detailOutlet(list.id, list.next, list.name)
                                }
                              >
                                {list.name}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_3_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_2_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_1_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_unit)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_3) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_2) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_1) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1)}
                              </span>
                            </td>
                            <td className="text-green-500">
                              {" "}
                              <span
                                className={`${
                                  parseFloat(list.m) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m)}
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.kontribusi) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.kontribusi} %
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.growth_ytd) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.growth_ytd} %
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.growth) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.growth} %
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot
                      style={{ textAlign: "right" }}
                      className="sticky bottom-0 z-10"
                    >
                      <tr className="bg-white border border-slate-800 font-bold">
                        <td
                          colSpan={"2"}
                          style={{
                            textAlign: "center",
                            left: "0px",
                            zIndex: "5",
                            position: "sticky",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          TOTAL
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          <span
                            className={`${
                              parseFloat(totalM3UnitChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3UnitChanel)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          <span
                            className={`${
                              parseFloat(totalM2UnitChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2UnitChanel)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalM1UnitChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1UnitChanel)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalMUnitChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalMUnitChanel)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM3Chanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3Chanel)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM2Chanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2Chanel)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM1Chanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1Chanel)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalMChanel) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {formatToRupiah(totalMChanel)}
                          </span>
                        </td>
                        <td
                          style={{
                            backgroundColor: "white",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className={`${
                              parseFloat(kontribusiTotalChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(kontribusiTotalChanel)} %
                          </span>
                        </td>
                        <td
                          style={{
                            backgroundColor: "white",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className={`${
                              parseFloat(growthYtdTotalChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {growthYtdTotalChanel} %
                          </span>
                        </td>
                        <td
                          style={{
                            backgroundColor: "white",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className={`${
                              parseFloat(growthTotalChanel) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {growthTotalChanel} %
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: "30px" }} ref={targetRefOutlet}>
          {showDetailOutlet ? (
            <div className="border-[1px] border-[#437998] rounded-xl p-2">
              <h3 className="font-semibold text-lg mx-2">
                Detail Data dari{" "}
                <span className="text-redBrave">
                  {name} - {nameChanel} - {nameOutlet}
                </span>
              </h3>
              <div className="">
                <div className="card-body">
                  <table
                    className="table table-bordered table-hover table-scroll"
                    id="example15"
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                      display: "block",
                      overflow: "scroll",
                      height: "420px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#AB6969",
                        color: "white",
                        textAlign: "center",
                        top: "0",
                        zIndex: "10",
                        position: "sticky",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr className="text-center">
                        <th
                          rowSpan={"2"}
                          style={{
                            left: "0",
                            zIndex: "5",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            backgroundColor: "#AB6969",
                          }}
                        >
                          NO
                        </th>
                        <th
                          rowSpan={"2"}
                          style={{
                            left: "60px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            backgroundColor: "#AB6969",
                          }}
                        >
                          NAMA
                        </th>
                        <th colSpan={"4"}>UNIT</th>
                        <th colSpan={"4"}>VALUE</th>
                        <th rowSpan={"2"}>KONTRIBUSI</th>
                        <th rowSpan={"2"}>GROWTH YTD</th>
                        <th rowSpan={"2"}>GROWTH</th>
                        <th rowSpan={"2"}>TRANSAKSI</th>
                      </tr>
                      <tr className="text-center">
                        <th>M-3</th>
                        <th>M-2</th>
                        <th>M-1</th>
                        <th>M</th>
                        <th>M-3</th>
                        <th>M-2</th>
                        <th>M-1</th>
                        <th>M</th>
                      </tr>
                    </thead>

                    <tbody style={{ textAlign: "right" }}>
                      {sortedDataOutlet.map((list, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                textAlign: "center",
                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                              width={"1%"}
                            >
                              {index + 1}.
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                textDecoration: "underline",
                                fontStyle: "italic",
                                color: "#3b82f6",
                                cursor: "pointer",
                                left: "60px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <span
                                onClick={() =>
                                  detailUserOutlet(list.id, list.name)
                                }
                              >
                                {list.name}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_3_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_2_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_1_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1_unit)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(list.m_unit) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.m_unit)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_3) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_2) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_1) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1)}
                              </span>
                            </td>
                            <td className="text-green-500">
                              {" "}
                              <span
                                className={`${
                                  parseFloat(list.m) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m)}
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.kontribusi) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.kontribusi} %
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.growth_ytd) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.growth_ytd} %
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.growth) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.growth} %
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {list.n_transaksi}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot
                      style={{ textAlign: "right" }}
                      className="sticky bottom-0 z-10"
                    >
                      <tr className="bg-white border border-slate-800 font-bold">
                        <td
                          colSpan={"2"}
                          style={{
                            textAlign: "center",
                            left: "0px",
                            zIndex: "5",
                            position: "sticky",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          OUTLET TRANSAKSI
                        </td>
                        <td colSpan={"4"}></td>
                        <td>
                          {countOutletZeroM3} / {dataOutlet.length}
                        </td>
                        <td>
                          {countOutletZeroM2} / {dataOutlet.length}
                        </td>
                        <td>
                          {countOutletZeroM1} / {dataOutlet.length}
                        </td>
                        <td>
                          {countOutletZeroM} / {dataOutlet.length}
                        </td>
                        <td colSpan={4}></td>
                      </tr>
                      <tr className="bg-white border border-slate-800 font-bold">
                        <td
                          colSpan={"2"}
                          style={{
                            textAlign: "center",
                            left: "0px",
                            zIndex: "5",
                            position: "sticky",
                            backgroundColor: "#ffffff",
                          }}
                        >
                          TOTAL
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          <span
                            className={`${
                              parseFloat(totalM3UnitOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3UnitOutlet)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalM2UnitOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2UnitOutlet)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalM1UnitOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1UnitOutlet)}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span
                            className={`${
                              parseFloat(totalMUnitOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalMUnitOutlet)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM3Outlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM3Outlet)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM2Outlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM2Outlet)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalM1Outlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(totalM1Outlet)}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              parseFloat(totalMOutlet) < 0 ? "text-red-500" : ""
                            }`}
                          >
                            {formatToRupiah(totalMOutlet)}
                          </span>
                        </td>
                        <td
                          style={{
                            backgroundColor: "white",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className={`${
                              parseFloat(kontribusiTotalOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {formatToRupiah(kontribusiTotalOutlet)} %
                          </span>
                        </td>
                        <td
                          style={{
                            backgroundColor: "white",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className={`${
                              parseFloat(growthYtdTotalOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {growthYtdTotalOutlet} %
                          </span>
                        </td>
                        <td
                          style={{
                            backgroundColor: "white",
                            textAlign: "center",
                          }}
                        >
                          <span
                            className={`${
                              parseFloat(growthTotalOutlet) < 0
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            {growthTotalOutlet} %
                          </span>
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: "30px" }} ref={targetRefOutletUser}>
          {showDetailOutletUser ? (
            <div className="border-[1px] border-[#437998] rounded-xl p-2">
              <h3 className="font-semibold text-lg mx-2">
                Data User dari{" "}
                <span className="text-redBrave">
                  {name} - {nameChanel} - {nameOutlet} - {nameOutletUser}
                </span>
              </h3>
              <div className="">
                <div className="card-body">
                  <table
                    className="table table-bordered table-hover table-scroll"
                    id="example204"
                    style={{
                      fontSize: "12px",
                      fontWeight: "semiBold",
                      display: "block",
                      overflow: "scroll",
                      height: "420px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#437998",
                        color: "white",
                        textAlign: "center",
                        top: "0",
                        zIndex: "10",
                        position: "sticky",
                        fontWeight: "reguler",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            left: "0",
                            zIndex: "5",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            backgroundColor: "#437998",
                          }}
                        >
                          NO
                        </th>
                        <th
                          style={{
                            left: "60px",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            backgroundColor: "#437998",
                          }}
                        >
                          UID
                        </th>
                        {/* <th>CT</th>
                        <th>RB</th>
                        <th>SLD</th>
                        <th>KU</th>
                        <th>TNR</th> */}
                        <th>M-3</th>
                        <th>M-2</th>
                        <th>M-1</th>
                        <th>M</th>
                        <th>GROWTH YTD</th>
                        <th>GROWTH</th>
                      </tr>
                    </thead>

                    <tbody style={{ textAlign: "right" }}>
                      {sortedDataOutletUser.map((list, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                textAlign: "center",
                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                              width={"1%"}
                            >
                              {index + 1}.
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                left: "60px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              {list.user_id === null ||
                              list.user_name === null ? (
                                <>Not Connected</>
                              ) : (
                                <>
                                  {list.user_id} / {list.user_name}
                                </>
                              )}
                            </td>
                            {/* <td>
                              <span
                                className={`${
                                  parseFloat(list.current_ct) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.current_ct)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {" "}
                              {list.current_rb === "100.00"
                                ? "100 %"
                                : `${list.current_rb} %`}
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.current_saldo) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.current_saldo)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.last_ku) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(list.last_ku)}
                              </span>
                            </td>
                            <td className="text-center">
                              <span
                                className={`${
                                  parseFloat(list.lifetime) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.lifetime}
                              </span>
                            </td> */}
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_3) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_3)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_2) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_2)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(list.m_1) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m_1)}
                              </span>
                            </td>
                            <td className="text-green-500">
                              {" "}
                              <span
                                className={`${
                                  parseFloat(list.m) < 0 ? "text-red-500" : ""
                                }`}
                              >
                                {formatToRupiah(list.m)}
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.growth_ytd) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.growth_ytd} %
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <span
                                className={`${
                                  parseFloat(list.growth) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {list.growth} %
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      {loader}
    </>
  );
}

export default ChartFour;

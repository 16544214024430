import React, { useState, useEffect } from "react";
import { getDataRatioArea } from "../../service/ratio/RatioService";
import Swal from "sweetalert2";
import Full from "../../load/Loading";
import "../../App.css";
import numeral from "numeral";

function ModalProductMonitor({
  onCloseModal,
  nameProduct,
  idProduct,
  team_id,
  month,
  division_id,
}) {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const [dataRatioArea, setDataRatioArea] = useState({});
  const [kontribusiTotalProduct, setKontribusiTotalProduct] = useState("");
  const [kontribusiTotalPdProduct, setKontribusiTotalPdProduct] = useState("");
  const [growthTotalProduct, setGrowthTotalProduct] = useState("");
  const [growthYtdTotalProduct, setGrowthYtdTotalProduct] = useState("");

  const dataArea = [];
  for (const key in dataRatioArea) {
    if (Object.hasOwnProperty.call(dataRatioArea, key)) {
      const element = dataRatioArea[key];
      dataArea.push(element);
    }
  }

  useEffect(() => {
    detailProduct();
  }, [idProduct]);

  const detailProduct = () => {
    showLoader();
    const payload = {
      rank: division_id,
      type: "BDU",
      search_name: "detail_product",
      search_id: idProduct,
      user_id: "",
      team_id: team_id,
      periode: month,
      id_type: "4",
    };

    getDataRatioArea(payload)
      .then((res) => {
        if (res.data.success == true) {
          console.log(res.data.data);
          setDataRatioArea(res.data.data["BDU"]);
          setKontribusiTotalProduct(res.data.kontribusi_total);
          setKontribusiTotalPdProduct(res.data.kontribusi_gp_total);
          setGrowthTotalProduct(res.data.growth_total);
          setGrowthYtdTotalProduct(res.data.growth_ytd_total);
          hideLoader();
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.data.message == "Expired token") {
          Swal.fire({
            title: err.response.data.message,
            showConfirmButton: false,
            icon: "warning",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("rank_name");
          localStorage.removeItem("division_id");
          localStorage.removeItem("team_id");
          localStorage.removeItem("id_type");
          localStorage.removeItem("rank");
          localStorage.removeItem("color");
          localStorage.removeItem("is_manager");
          window.location = "/";
        }
        hideLoader();
      });
  };

  // Inisialisasi variabel total
  let totalM3UnitAreaGrand = 0;
  let totalM2UnitAreaGrand = 0;
  let totalM1UnitAreaGrand = 0;
  let totalMUnitAreaGrand = 0;
  let totalM3AreaGrand = 0;
  let totalM2AreaGrand = 0;
  let totalM1AreaGrand = 0;
  let totalMAreaGrand = 0;

  // Map dataArea dan tambahkan nilai ke total
  dataArea.forEach((list) => {
    totalM3UnitAreaGrand += parseInt(list.m_3_unit);
    totalM2UnitAreaGrand += parseInt(list.m_2_unit);
    totalM1UnitAreaGrand += parseInt(list.m_1_unit);
    totalMUnitAreaGrand += parseInt(list.m_unit);
    totalM3AreaGrand += parseInt(list.m_3);
    totalM2AreaGrand += parseInt(list.m_2);
    totalM1AreaGrand += parseInt(list.m_1);
    totalMAreaGrand += parseInt(list.m);
  });

  const sortedDataArea = dataArea.sort((a, b) => b.m - a.m);

  useEffect(() => {
    if (sortedDataArea.length !== 0) {
      const scripts = [];
      const datatableScript = document.createElement("script");
      datatableScript.async = true;
      datatableScript.textContent = `$(document).ready(function() {
            $('#table5').DataTable({
                "retrieve": true,
                "paging": false,
                "lengthChange": false,
                "searching": true,
                "ordering": true,
                "info": false,
                "autoWidth": false,
                "responsive": false,
            });
        });`;

      document.body.appendChild(datatableScript);
      scripts.push(datatableScript);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [sortedDataArea]);

  //konversi nilai
  const formatToRupiah = (value) => {
    return numeral(value).format("0,0"); // Format angka dengan koma sebagai pemisah ribuan
  };

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-60 z-30 max-h-[100vh]"></div>

      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-40 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className=" pb-12 mx-[3%] my-[2%] max-w-8xl">
          {/* Modal content */}
          <div className="bg-white rounded-lg shadow ">
            {/* Modal header */}
            <div className="flex items-center justify-between p-1 md:p-5 border-b rounded-t dark:border-gray-600">
              <h5 className="font-bold text-[20px] p-2">
                {" "}
                Detail Data dari{" "}
                <span className="text-redBrave">{nameProduct}</span>
              </h5>
              <button
                onClick={onCloseModal}
                type="button"
                className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl w-12 h-12 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-2 md:p-5 relative ">
              {/* <div className="w-full lg:w-full lg:my-6 lg:mx-2 my-12">
                <div className="flex items-center gap-4">
                  <h5 className="flex font-regular text-[16px] mb-0">
                    Silakan Pilih Tanggal:
                  </h5>
                  <div className="flex items-center space-x-4">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      startDate={startDate}
                      endDate={endDate}
                      popperPlacement="bottom-end"
                      selectsStart
                      dateFormat="dd MMMM yyyy"
                      placeholderText="Start Date"
                      className="p-2 border rounded-md"
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      startDate={startDate}
                      endDate={endDate}
                      selectsEnd
                      minDate={startDate}
                      popperPlacement="bottom-end"
                      dateFormat="dd MMMM yyyy"
                      placeholderText="End Date"
                      className="p-2 border rounded-md"
                    />
                    <button
                      onClick={() => DataLog()}
                      className="bg-redBrave text-white px-4 py-2 rounded-md"
                    >
                      Proses
                    </button>
                  </div>
                </div>
              </div> */}

              <div className="border-[1px] border-[#437998] rounded-xl p-2 mb-4">
                <div>
                  <div className="card-body" style={{ marginTop: "-20px" }}>
                    <div>
                      <table
                        style={{
                          fontSize: "12px",
                          fontWeight: "semiBold",
                          display: "block",
                          overflow: "scroll",
                          height: "480px",
                          width: "100%",
                          position: "relative",
                        }}
                        className="table table-bordered table-hover table-scroll"
                        id={`table5`}
                      >
                        <thead
                          style={{
                            backgroundColor: "#6BB7B7",
                            color: "white",
                            textAlign: "center",
                            top: "0",
                            zIndex: "10",
                            position: "sticky",
                            verticalAlignAlign: "middle",
                            fontWeight: "reguler",
                          }}
                        >
                          <tr></tr>
                          <tr>
                            <th
                              rowSpan={"2"}
                              width="1%"
                              style={{
                                left: "0",
                                zIndex: "5",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#6BB7B7",
                              }}
                            >
                              NO
                            </th>
                            <th
                              rowSpan={"2"}
                              width="9.6%"
                              style={{
                                left: "62px",
                                zIndex: "10",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#6BB7B7",
                              }}
                            >
                              PRD
                            </th>
                            <th
                              rowSpan={"2"}
                              width="9.6%"
                              style={{
                                left: "145px",
                                zIndex: "10",
                                position: "sticky",
                                verticalAlignAlign: "middle",
                                backgroundColor: "#6BB7B7",
                              }}
                            >
                              PRD GROUP
                            </th>
                            <th colSpan={"4"} width="25%">
                              UNIT
                            </th>
                            <th colSpan={"4"} width="25%">
                              VALUE
                            </th>
                            <th colSpan={"2"} width="5%">
                              KONTRIBUSI
                            </th>
                            <th rowSpan={"2"} width="5%">
                              GROWTH YTD
                            </th>
                            <th rowSpan={"2"} width="5%">
                              GROWTH
                            </th>
                          </tr>
                          <tr>
                            <th width="6.3%">M-3</th>
                            <th width="6.3%">M-2</th>
                            <th width="6.3%">M-1</th>
                            <th width="6.3%">M</th>
                            <th width="6.3%">M-3</th>
                            <th width="6.3%">M-2</th>
                            <th width="6.3%">M-1</th>
                            <th width="6.3%">M</th>
                            <th width="6.3%">PG</th>
                            <th width="6.3%">TOTAL</th>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: "right" }}>
                          {sortedDataArea.map((list, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    textAlign: "center",
                                    left: "0px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  {index + 1}.
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",

                                    left: "62px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  <span>{list.name}</span>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    left: "145px",
                                    zIndex: "5",
                                    position: "sticky",
                                    backgroundColor: "#ffffff",
                                  }}
                                >
                                  {list.product_list_group}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(list.m_3_unit) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_3_unit)}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(list.m_2_unit) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_2_unit)}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(list.m_1_unit) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_1_unit)}
                                  </span>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <span
                                    className={`${
                                      parseFloat(list.m_unit) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_unit)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(list.m_3) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_3)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(list.m_2) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_2)}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={`${
                                      parseFloat(list.m_1) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m_1)}
                                  </span>
                                </td>
                                <td className="text-green-500">
                                  <span
                                    className={`${
                                      parseFloat(list.m) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {formatToRupiah(list.m)}
                                  </span>
                                </td>

                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {list.kontribusi_gp}%
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {list.kontribusi}%
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <span
                                    className={`${
                                      parseFloat(list.growth_ytd) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {list.growth_ytd} %
                                  </span>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <span
                                    className={`${
                                      parseFloat(list.growth) < 0
                                        ? "text-red-500"
                                        : ""
                                    }`}
                                  >
                                    {list.growth} %
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot className="bg-white border border-slate-800 font-bold sticky bottom-0 z-10">
                          <tr
                            style={{ textAlign: "right" }}
                            className="bg-white border border-slate-800 font-bold sticky bottom-0 z-10"
                          >
                            <td
                              colSpan={3}
                              style={{
                                textAlign: "center",

                                left: "0px",
                                zIndex: "5",
                                position: "sticky",
                                backgroundColor: "#ffffff",
                              }}
                            >
                              <span>GRAND TOTAL</span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(
                                    totalM3UnitAreaGrand.toLocaleString("id-ID")
                                  ) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM3UnitAreaGrand)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(
                                    totalM2UnitAreaGrand.toLocaleString("id-ID")
                                  ) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM2UnitAreaGrand)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(
                                    totalM1UnitAreaGrand.toLocaleString("id-ID")
                                  ) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM1UnitAreaGrand)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(
                                    totalMUnitAreaGrand.toLocaleString("id-ID")
                                  ) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalMUnitAreaGrand)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(totalM3AreaGrand) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM3AreaGrand)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(totalM2AreaGrand) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM2AreaGrand)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(totalM1AreaGrand) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalM1AreaGrand)}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`${
                                  parseFloat(totalMAreaGrand) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {formatToRupiah(totalMAreaGrand)}
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(kontribusiTotalPdProduct) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {kontribusiTotalPdProduct} %
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(kontribusiTotalProduct) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {kontribusiTotalProduct} %
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(growthYtdTotalProduct) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {growthYtdTotalProduct} %
                              </span>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className={`${
                                  parseFloat(growthTotalProduct) < 0
                                    ? "text-red-500"
                                    : ""
                                }`}
                              >
                                {growthTotalProduct} %
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
}

export default ModalProductMonitor;
